import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { ASUHeader } from "@asu/component-header/dist/asuHeader.es";
import { Footer } from "../Footer";

import desktopLogo from "../../../assets/img/arizona-state-university-logo-vertical.png";
import mobileLogo from "../../../assets/img/arizona-state-university-logo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";

import parseHtml from "html-react-parser";
import { stripHtml } from "string-strip-html";
import { isInternalLink } from "is-internal-link";

import ErrorPage from "../ErrorPage";

const HeaderSection = styled.div`
  header {
    position: sticky;

    .content-container {
      font-size: 1rem;
      border: 0;
      margin: 0;
      height: fit-content;
      align-items: start;
      flex-direction: row;
    }

    a {
      cursor: pointer;
    }
  }
`;

function Content(props) {
  const history = useHistory();
  const location = useLocation();

  const [isAuthenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(Cookies.get("username"));
  const [targetNID, setTargetNID] = useState();
  const [menu, setMenu] = useState(props.data.menu);
  const [isCustom, setCustom] = useState(props.custom);

  const handleNavClick = (e) => {
    e.preventDefault();
    const { result } = stripHtml(e.target.innerHTML);
    findNavDestination(result,menu);
  };

  const findNavDestination = (target,menu) => {
    for (let i = 0; i < menu.length; i++) {
      if (Array.isArray(menu[i].items)) {
        findNavDestination(target,menu[i].items)
      }
      else if (target === menu[i].title) {
        const nid = menu[i].nid;
        setTargetNID(nid);
        if (isCustom) history.push(menu[i].alias);
        else history.push("/" + props.group + menu[i].alias);
      }
    }
  }

  const buildNav = (items) => {
    let navTree = [];
    if (Array.isArray(items))
      for (let i = 0; i < items.length; i++) {
        if (Array.isArray(items[i].items)) {
          navTree.push({
            text: items[i].title,
            href: "/",
            items: [buildNav(items[i].items)],
          });
        } else {
          navTree.push(buildNavNode(items[i], isCustom, i === 0));
        }
      }
    return navTree;
  };

  const buildNavNode = (item, isCustom, isFirst) => {
    const title = item.title;
    const alias = item.alias;

    const path = decodeURIComponent(window.location.pathname);
    let isSelected = false;

    if (
      (isCustom && (path === alias || (isFirst && path === "/"))) ||
      (!isCustom &&
        (path === "/" + props.group + alias ||
          (isFirst && path === "/" + props.group)))
    ) {
      isSelected = true;
    }

    if (isInternalLink(alias)) {
      return {
        onClick: (e) => handleNavClick(e),
        text: title,
        href: isCustom ? alias : "/" + props.group + alias,
        type: "",
        selected: isSelected,
      };
    } else {
      return {
        text: title,
        href: alias,
        class: "externalLink",
      };
    }
  };

  const setExternalTargets = () => {
    const links = Array.from(document.querySelectorAll("a.externalLink"));
    links.forEach((element) => {
      element.setAttribute("target", "_blank");
    });
  };

  // 🧠 FIX: Handle NID selection in useEffect to avoid re-renders
  useEffect(() => {
    const path = decodeURIComponent(window.location.pathname);
    let nidToSet;

    const findMatchingNID = (items, isFirst = false) => {
      for (const item of items) {
        if (Array.isArray(item.items)) {
          findMatchingNID(item.items, false);
        } else {
          const alias = item.alias;
          const nid = item.nid;

          const match =
            (isCustom && (path === alias || (isFirst && path === "/"))) ||
            (!isCustom &&
              (path === "/" + props.group + alias ||
                (isFirst && path === "/" + props.group)));

          if (match && targetNID !== nid) {
            nidToSet = nid;
            break;
          }
        }
      }
    };

    if (Array.isArray(menu)) findMatchingNID(menu, true);
    if (nidToSet !== undefined) setTargetNID(nidToSet);
  }, [location.pathname, menu, isCustom, props.group]);

  useEffect(() => {
    setExternalTargets();
  }, []);

  useEffect(() => {
    document.title = props.data.site_name;
  }, [props.data.site_name]);

  const nav = buildNav(menu);

  let content = "";
  let redirect = false;

  if (Array.isArray(props.data.redirects)) {
    props.data.redirects.forEach((i) => {
      if (props.page === i.path) {
        redirect = true;
        window.location.replace(i.target);
      }
    });
  }

  if (Array.isArray(props.data.content)) {
    props.data.content.forEach((i) => {
      if (targetNID === i.nid) {
        content = parseHtml(i.content);
      }
    });
  }

  const baseURL = !isCustom ? "/" + props.group + "/" : "/";

  return (
    <div>
      <HeaderSection>
        <ASUHeader
          logo={{
            alt: "logo",
            src: desktopLogo,
            mobileSrc: mobileLogo,
          }}
          baseUrl={baseURL}
          loggedIn={isAuthenticated}
          userName={user}
          onLoginClick={() => {
            window.open("https://sites.thecollege.asu.edu/cas");
          }}
          navTree={nav}
          title={props.data.site_name}
          buttons={[]}
          searchUrl={"https://search.asu.edu/search"}
        />
      </HeaderSection>

      {props.status === 500 ? (
        <ErrorPage {...props} error="" custom={isCustom} />
      ) : content.length === 0 ? (
        redirect ? (
          <ErrorPage {...props} error="304" custom={isCustom} />
        ) : props.page === undefined ? (
          <ErrorPage {...props} error="500" custom={isCustom} />
        ) : (
          <ErrorPage {...props} error="404" custom={isCustom} />
        )
      ) : (
        <div className="main-container">{content}</div>
      )}

      <Footer />
    </div>
  );
}

export { Content };