import React, { useState, useEffect } from 'react'
// base components
import { Content, Footer } from "../core/components";
import { CookieConsent } from '@asu/component-cookie-consent/dist/asuCookieConsent.es';
import LoadingOverlay from 'react-loading-overlay'
import SyncLoader from 'react-spinners/SyncLoader'
import styled from 'styled-components'
import axios from "axios";

import DefaultRoute from "../core/components/ErrorPage";

const StyledLoader = styled(LoadingOverlay)`
  ._loading_overlay_overlay {
    position: fixed;
    background-color: rgba(255, 255, 255, 1);
  }
  &._loading_overlay_spinner {
    top: 50%;
    left: 50%;
  }
`

function TargetedRoute(props) {
  //console.log(props);
  LoadingOverlay.propTypes = undefined
  const [isLoading, setIsLoading] = useState(true);
  const [data,setData] = useState({"site_name":"Faculty Sites","menu":[],"content":[],"redirects":[]});
  const [status, setStatus] = useState();
  const [isCustom, setCustom] = useState( props.custom );
  const [targetGroup, setGroup] = useState( ( isCustom ? props.group : props.match.params.group ) );

  const getData=()=>{
    setIsLoading(true);
    axios({
      method: 'get',
      url: 'https://sites.thecollege.asu.edu/clas_group_api/'+targetGroup,
      withCredentials: false
      })
      .then((response) => {
        // Empty responses from the server are [] instead of {}.
        if ( !Array.isArray(response.data) ) {
          //response.data.redirects = [{"path":"/R&E/test","target":"https://sites.thecollege.asu.edu/sites/default/files/2024-03/ASU-ModInstrReport2023.pdf"}];
          setData(response.data);
          setStatus(response.status);
          //console.log(response.data);
          setIsLoading(false);
        }
        //else {
        //  window.location.href = "/";
        //}
      })
      .catch(function (error) {
        console.log("Error caught!");
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.status);
          setStatus(error.response.status);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          setStatus(500);
        } else {
          // Something happened in setting up the request that triggered an Error
          setStatus(500);
          console.log('Error', error.message);
        }
        //console.log(error.config);

        setIsLoading(false);

      });
  }

  useEffect( ()=>getData(),[] );
  let targetPage = window.location.pathname;
  if ( !isCustom ) targetPage = targetPage.replace(new RegExp("^/"+targetGroup),"");

  //console.log("targetGroup: "+targetGroup);
  //console.log("targetPage: "+targetPage);
  return (
    <div>
    { isLoading === false ?
        <Content status={status} data={data} group={targetGroup} page={targetPage} custom={isCustom}></Content>
      :
        <StyledLoader
          active={isLoading}
          spinner={<SyncLoader size="30px" color="#8C1D40" /> }
        >

        </StyledLoader>
    }
    <CookieConsent enableCookieConsent={true} expirationTime={90}/>
    </div>
  );

}


export default TargetedRoute;
